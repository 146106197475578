import service from '@/utils/request';
/**
 * 话费充值利润统计
 * @param {Object} params { year, month }
 */

export var repPhoneChargeProfit = function repPhoneChargeProfit(params) {
  return service({
    url: "/resource/phone_order/statistics",
    method: 'get',
    params: params
  });
};
/**
 * 话费充值订单列表
 * @param {Object} params { page, pageSize, app_id, sc_type: 渠道商, status, timeout_status, start_time, end_time, phone, leave_time }
 */

export var reqPhoneChargeList = function reqPhoneChargeList(params) {
  return service({
    url: "/resource/phone_order",
    method: 'get',
    params: params
  });
};
/**
 * 话费充值进度
 * @param {Object} params { id }
 */

export var repOrderProgress = function repOrderProgress(params) {
  return service({
    url: "/resource/phone_order/progress",
    method: 'get',
    params: params
  });
};
/**
 * 话费充值订单 退款
 * @param {Object} data { ids }
 */

export var reqRefund = function reqRefund(data) {
  return service({
    url: "/resource/phone_order/refund",
    method: 'post',
    data: data
  });
};
/**
 * 话费充值订单 转秒充
 * @param {Object} data { ids }
 */

export var reqSwitchToSeconds = function reqSwitchToSeconds(data) {
  return service({
    url: "/resource/phone_order/tosec",
    method: 'post',
    data: data
  });
};
/**
 * 话费充值订单 转手动充值
 * @param {Object} data { ids }
 */

export var reqManualRecharge = function reqManualRecharge(data) {
  return service({
    url: "/resource/phone_order/tohand",
    method: 'post',
    data: data
  });
};
/**
 * 获取手机充值配置
 * @param {Object} params { column: PhoneSegmentLimit-号段;PhoneAutoRefund-自动打款 }
 */

export var repPhoneChargeConfig = function repPhoneChargeConfig(params) {
  return service({
    url: "/config/get",
    method: 'get',
    params: params
  });
};
/**
 * 设置号段
 * @param {Object} data { column: PhoneSegmentLimit-号段;PhoneAutoRefund-自动打款, column_value }
 */

export var reqSavePhoneChargeConfig = function reqSavePhoneChargeConfig(data) {
  return service({
    url: "/config/set",
    method: 'post',
    data: data
  });
};
/**
 * 全部、快充、慢充的成功率统计
 * @param {Object} params { start_time, end_time }
 */

export var repPhoneChargeSuccessRate = function repPhoneChargeSuccessRate(params) {
  return service({
    url: "/resource/phone_order/type/statistics",
    method: 'get',
    params: params
  });
};
/**
 * 渠道成功率
 * @param {Object} params { start_time, end_time }
 */

export var repPhoneChargeCanalRate = function repPhoneChargeCanalRate(params) {
  return service({
    url: "/resource/phone_order/canal/statistics",
    method: 'get',
    params: params
  });
};
/**
 * 电费利润统计
 * @param {Object} params { start_time, end_time }
 */

export var repElectricProfitRate = function repElectricProfitRate(params) {
  return service({
    url: "/resource/power_order/income",
    method: 'get',
    params: params
  });
};
/**
 * 电费成功率统计
 * @param {Object} params { start_time, end_time }
 */

export var repElectricSuccessRate = function repElectricSuccessRate(params) {
  return service({
    url: "/resource/power_order/successrate",
    method: 'get',
    params: params
  });
};
/**
 * 电费数据列表
 * @param {Object} params { start_time, end_time }
 */

export var repElectricList = function repElectricList(params) {
  return service({
    url: "/resource/power_order/list",
    method: 'get',
    params: params
  });
};